import './footer-component.scss';

const Footer = () => {

return(
    <div className='foot'>
        <p className='footp'>COPYRIGHT © 2023 Ryan D</p>
        <p className='footp'>ALL RIGHTS RESERVED</p>
    </div>
)

}
export default Footer;