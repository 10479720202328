import './dropedDown.scss';


function DropedDown() {
    return(
    <div className='dropDown'>
        <a className='dropA' href="#service">Services</a>
        <a className='dropA' href="#about" >About</a>
        <a className='dropA' href="#gal">Gallery</a>
    </div>
    )
}

export default DropedDown;