import './popup.scss'

const Popup = (image) =>{

    return(
        
        <div className="pop">
            <img src={image.image} alt=''/>
        </div>

    )
}

export default Popup;