import './About.scss';

const AboutUs = () => {


    return(
        <div id='about' className='aboutUs-container'>
        <h2>About us</h2>
        <p>
        
We are a family business looking to provide professional tree care at competitive prices.

We offer Knowledge and competitive quotes to all of Anne Arundel County, aiming to advice and address projects and property issues or turn your landscaping dreams in reality.

Our company is specialized in tree services, serving the Anne Arundel area, giving the our best to customers looking for the right contractor able to fit your standards to ensure a healthy and green beauty for each project, minimizing amount of maintenance in the future.

Our Residential clientele is constantly and continually expanding from private customers to big companies ensuring  high quality work for every single job done reaching "5 stars" customer satisfaction levels.

We will advise you timely with different techniques for trees maintenance or removals needed, dedicating all our effort as tree and business experts to bring up your property to curb appeals stages. Our vision, knowledge, strategies and experience will help you avoid inconveniences and reach successfully the futuristic appearance your place needs.



       </p>
       <h3>"The epitome of tree services"</h3>
        </div>
    )
}

export default AboutUs;