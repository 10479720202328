import './drop-component.scss'


const DropComponent = (tex, t, l) => {


const text = tex.tex;
// console.log(text)
console.log(t)


return(
    <div className="drop" style={{top:{t},left:{l}}}>
    <p>{text}</p>
    </div>
    )
    
}

export default DropComponent;